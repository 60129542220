.container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 1rem;
}

.app-bar {
  background: var(--blue);
  color: white;
}
.app-bar .container,
.section-about .container,
.page-footer .container {
  padding: 1rem 2rem;
}

.app-bar h1 {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.section {
  padding: 2rem 0;
}
.section-hero {
  background: url("./jagged-blue-rect.svg") no-repeat 50% -10rem;
  background-size: cover;
  color: var(--blue);
}

.section-hero__image {
  display: block;
  max-width: 20rem;
  margin: auto;
}

.section-hero__text {
  background: white;
  margin: 1rem auto;
  padding: 1rem;
  max-width: 32rem;
  text-align: center;
}

.section-hero__text h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
}
.section-hero__text p {
  font-size: 1.5rem;
  margin: 0;
}

.page-footer {
  background: var(--blue);
  color: var(--blue-light);
}
.page-footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-media {
  list-style: none;
  display: flex;
  padding: 0;
}
.social-media li a {
  display: inline-block;
  padding: 0.5rem;
  color: var(--blue-light);
}

.social-media li a:hover {
  color: white;
}

.App {
  filter: hue-rotate(0deg);
  animation: color-rotate 30s infinite;
}

.section-about__img {
  max-width: 20rem;
  display: block;
  margin: 1rem auto;
}

@media (min-width: 40rem) {
  .section-about .section__contents {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .section-about__img {
    margin: 0 2rem 0 0;
    width: 33%;
  }
}

.btn {
  font-size: 1rem;
  color: var(--blue);
  display: inline-block;
  border: 0.125rem solid var(--blue);
  text-decoration: none;
  padding: 0.5rem 1em;
  margin: 1rem;
  text-transform: uppercase;
}

.btn:hover {
  background: var(--blue);
  color: white;
}

@keyframes color-rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
