*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --blue: #0b18ff;
  --blue-medium: #777aff;
  --blue-light: #d6d7ff;
  --blue-lighter: #f2f3ff;
  --red: #ff0b0b;
  --red-light: #ffdada;
  --yellow: #fffe00;
  --font-family: "Proxima Nova", "proxima-nova", sans-serif;
  --condensed-font-family: "Proxima Nova Extra Condensed",
    "proxima-nova-extra-condensed", sans-serif;
  --monospace-font-family: source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
}

code {
  font-family: var(--monospace-font-family);
}

img {
  max-width: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
