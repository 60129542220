.section__heading {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  color: var(--blue);
  letter-spacing: 0.25rem;
  position: relative;
  z-index: 0;
}
.section__heading:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--yellow);
  width: 10rem;
  height: 0.666rem;
  z-index: -10;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
}

.project-section:nth-of-type(3n + 1) {
  background: var(--blue-light);
}
.project-section:nth-of-type(3n + 1) .project__info-container {
  border-color: var(--blue-medium);
}

.project-section:nth-of-type(3n + 2) {
  background: var(--blue-lighter);
}

.section-about p {
  color: var(--blue);
  font-size: 1.25rem;
}
