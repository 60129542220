.project {
  --color-text: var(--blue);
  --color-background: white;
  --color-border: var(--blue-light);
  color: var(--color-text);
  position: relative;
  z-index: 10;
  margin: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.project:hover {
  box-shadow: 0 0.5rem 0.5rem hsla(0, 0, 0, 0.5);
}

.project__info-container {
  border: 2px solid var(--color-border);
  background: var(--color-background);
  padding: 1.25rem;
  flex: auto;
}

.project:hover .project__info-container {
  display: block;
}

.project__heading {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 2.5rem;
  margin: 0;
  position: relative;
  line-height: 1;
}
.project__heading a {
  text-decoration: none;
}

.project__heading:after {
  content: '';
  display: block;
  width: 2.5rem;
  height: 2px;
  background: var(--color-text);
  margin: 1rem 0;
}

.project__subheading {
  font-size: 0.9rem;
  color: var(--color-subheading);
  margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: normal;
}

.project__blurb {
  margin: 0;
  font-size: 1rem;
}

.project__tags {
  font-size: 0.9rem;
  list-style-type: none;
  padding: 0;
}
.project__tags li {
  margin-right: 0.25rem;
  padding: 0.25rem 0.5rem;
  background: var(--color-text);
  color: var(--color-background);
  display: inline-block;
}

.project__thumbnail {
  filter: sepia(100%) hue-rotate(180deg);
  display: block;
}

.project:hover .project__thumbnail {
  filter: none;
}

.project__links {
  list-style: none;
  padding: 0;
  display: flex;
}

.project__links a {
  display: inline-block;
  margin-right: 0.5rem;
}
